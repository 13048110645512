<script setup>
import { ref, onMounted, watch } from "vue";
import { useUserStore } from "../../stores/user";
import c3api from "../../c3api";
import { useRouter, useRoute } from "vue-router/auto";
import FabButton from "../../components/FabButton.vue";
import pageTitle, { setPageTitle } from "../../utils/pageTitle";
import { shortDateWithTimeTz } from "../../utils/dateUtils";
import { useLoadingStore } from "../../stores/loading";
import { useToast } from "vue-toastification";

const userStore = useUserStore();
const router = useRouter();
const route = useRoute();
let changelogs = ref(null);
const page = ref(parseInt(route.query.page) || 1);
const filters = ref(null);
const searchText = ref(null);
const sortBy = ref("id");
const loading = useLoadingStore();
const toast = useToast();

onMounted(() => {
  setPageTitle("Manage Changelogs");
  loading.state(true);
  getChangelogs();
  loading.state(false);
});

watch(page, () => {
  getChangelogs();
});

const getChangelogs = async () => {
  const params = {
    filters: filters.value,
    sort: sortBy.value,
  };
  c3api
    .get(`/changelogs?page=${page.value}`, { params })
    .then((response) => {
      changelogs.value = response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

const deleteChangelog = async (id) => {
  const confirmation = confirm(`Are you sure you want to delete changelog ${id}?`);
  if (confirmation) {
    loading.state(true);
    c3api
      .delete(`/changelogs/${id}`)
      .then((response) => {
        toast.success(`Changelog ${id} deleted successfully`);
        getChangelogs();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        loading.state(false);
      });
  }
};

function updatePage(newPage) {
  router.push({ query: { page: newPage } });
}

watch(searchText, () => {
  if (searchText.value.length > 0) {
    page.value = 1;
    filters.value = [
      {
        column: "title",
        condition: "like",
        value: searchText.value,
      },
    ];
  } else {
    filters.value = null;
  }
  getChangelogs();
});
</script>

<template>
  <div v-if="userStore.user && changelogs">
    <v-card-title>{{ pageTitle }}</v-card-title>
    <v-card-subtitle
      >Use this section to manage changelogs. Only managers can see this page.
    </v-card-subtitle>
    <v-card flat class="mx-4 px-4 mt-4">
      <div class="mt-4">
        <v-text-field
          v-model="searchText"
          variant="outlined"
          prepend-inner-icon="mdi-text-box-search-outline"
          label="Search by title"
          color="indigo-lighten-2"
          flat
        ></v-text-field>
        <v-table>
          <thead class="">
            <tr>
              <th class="text-left">#</th>
              <th class="text-left">Title</th>
              <th class="text-left">Created By</th>
              <th class="text-left">Created At</th>
              <th class="text-left">Actions</th>
            </tr>
          </thead>
          <tbody v-if="loading.isLoading">
            <tr>
              <td colspan="4" class="text-center">
                <v-progress-circular
                  size="30"
                  indeterminate
                  color="blue-darken-2"
                ></v-progress-circular>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!loading.isLoading && changelogs.data.length == 0">
            <tr>
              <td colspan="4" class="text-center">No changelogs found.</td>
            </tr>
          </tbody>
          <tbody
            v-if="!loading.isLoading && changelogs && changelogs.data.length > 0"
            class=""
          >
            <tr
              v-for="changelog in changelogs.data"
              :key="changelog.id"
              style="cursor: pointer"
            >
              <td width="1%">{{ changelog.id }}.</td>
              <td @click="router.push(`/changelogs/${changelog.id}`)">
                {{ changelog.title }}
              </td>
              <td>{{ changelog.created_by.name }}</td>
              <td>{{ shortDateWithTimeTz(changelog.created_at) }}</td>
              <td>
                <v-menu>
                  <template v-slot:activator="{ props }">
                    <v-btn
                      density="comfortable"
                      icon="mdi-dots-horizontal"
                      elevation="0"
                      v-bind="props"
                    ></v-btn>
                  </template>

                  <v-list>
                    <v-list-item :to="`/changelogs/${changelog.id}`">
                      <v-list-item-title>View</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="`/changelogs/${changelog.id}/edit`">
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteChangelog(changelog.id)">
                      <v-list-item-title class="text-error">Delete</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </v-table>
      </div>
    </v-card>
    <div class="text-center mt-4" v-if="changelogs && changelogs.total_pages > 1">
      <v-pagination
        v-model="page"
        :length="changelogs.total_pages"
        @update:modelValue="updatePage"
        rounded="circle"
      ></v-pagination>
    </div>
  </div>
  <FabButton
    to="/changelogs/new"
    prepend-icon="mdi-plus"
    label="New Changelog"
    v-if="userStore.user?.employee_profile?.is_manager"
  />
</template>
